.LandingPageHeader{
   height: calc(100vh - 96px);
}
.LandingPageHeader-inner-container{
    width: 100%;
    height: 100%;
}
.header-content{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.header-content-h1{
    font-weight: 500;
    font-size: 60px;
    margin-bottom: 20px;
    color:#FFFFFF;
}
.header-content p{
    padding: 10px 0px;
    width: 40%;
    font-size: 20px;
    margin: auto;
    color:#FFFFFF;
}
.header-content button{
    margin: 10px;
    background-color:#1E52AE;
    color: #fff;
    border-radius: 40px;
    border-style: none;
    padding:20px 30px;
    width:200px;
}
.nav-icon-container{
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(167,199,252,1) 50%, rgba(196,217,253,1) 50%);
    display: inline-block;
    width:40px;
    margin-top: 70px;
    height:40px;
}
.nav-icon-container i{
    font-size: 30px;
    padding-top: 5px;
    color:rgba(22, 164, 222, 1);
}
.header-btn-wrapper button{
    transition: all .3s ease-out;
}
.header-btn:hover{
    transform:scaleX(1.1);
}
@media screen and (max-width: 1270px){
    .header-content-h1{
        font-size: 52px;
    }
    .LandingPageHeader-inner-container{
        background-size:700px 500px;
        background-position: 50% 0%;
    }
    .header-content p{
        width: 75%;
    }
}
@media screen and (max-width: 1020px){
    .header-content p {
        padding: 10px 100px;
    }
    .header-content-h1{
        font-size: 48px;
    }
    .header-content p{
        width: 90%;
    }
}
@media screen and (min-height: 800px){
    /* .LandingPageHeader{
        height: unset;
    } */
    /* .header-content p{
        width: 80%;
    } */
}

@media screen and (max-width: 760px){
    .header-content {
        padding: 0px 10px;
    }
    .header-content-h1 {
        font-size: 45px;
        margin-top: 100px;
    }
    .LandingPageHeader-inner-container{
        background-size:500px 400px;
        background-position: 50% 0%;
    }
    .nav-icon-container{
        margin-top: 50px;
    }
    .header-content p{
        width: 95%;
        padding: 10px 60px;
        font-size: 12px;
    }
}

@media screen and (max-width: 530px){
    .header-content-h1 {
        margin-bottom: 5px;
        margin-top: 70px;
        font-size: 45px;
    }
    .header-btn-wrapper button{
        min-width: 180px;
        font-size: 15px;
        padding:20px 10px;
    }
    .LandingPageHeader-inner-container{
        background-size:400px 300px;
        background-position: 50% 20%;
    }
    .header-content p{
        width: 100%;
    }
}
@media screen and (max-width: 447px){
    .LandingPageHeader-inner-container {
        background-size: 300px 250px;
        background-position: 50% 10%;
    }
    .header-btn-wrapper button{
        padding:15px 10px;
    }
    .header-content p {
        padding: 10px 30px;
    }

}
@media screen and (max-width: 400px){
    .header-content-h1 {
        font-size: 38px;
    }
}