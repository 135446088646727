.team-bio{
    position: fixed;
    overflow:auto;
    top:50%;
    left: 50%;
    margin-top: -275px;
    margin-left: -350px;
    width: 700px;
    height: 550px;
    background-color: white;
    z-index: 75;
    animation: slide-down 300ms ease-out forwards;
}
@keyframes slide-down {
    from {
      opacity: 1;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
.team-bio-header{
    background:#849DBE ;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 40px;
    top: 0;
    position: sticky;
    left:0;
    width: 100%;
}
.team-bio-header i{
    color:#FFFFFF;
    font-size: 25px;
    cursor: pointer;
}
.team-bio-footer button{
    color:#9FA3A7 ;
    border-radius: 5px;
    border-style: none;
    padding-top: 5px;
}
.team-bio-content-contianer{
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
    padding: 40px 0px;
}
.team-bio-content{
    width: 50%;
    overflow: auto;
    padding: 20px 30px 20px 0px;
}
.team-bio-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.team-bio-img img{
    width: 80%;
    height: 100%;
}
.team-bio-footer{
    bottom:0;
    left:0;
    background:#849DBE ;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 40px;
    bottom: 0;
    position: fixed;
}
.team-bio-content h1{
    font-size: 32px;
}
@media screen  and (max-width: 750px){
    .team-bio{
        width: 90%;  
        margin-left: -45%;
    }
    .team-bio-content-contianer{
        overflow: auto;
        display: block;
        padding: 50px 20px;
    }
    .team-bio-content{
        overflow: unset;
        display: inline;
        margin-right:20px;
    }
    .team-bio-img{
        display: inline;
        height: 50%;
        width: 40%;
        float: left;
        margin-right: 20px;
    }
    .team-bio-img img{
        height: 100%;
        width: 100%;
    }
}
@media screen  and (max-width: 550px){
    .team-bio-content h1{
        font-size: 20px;
    }
}

@media screen  and (max-width: 400px){
    .team-bio-content h1{
        font-size: 18px;
    }
    .team-bio-img{
        float: unset;
    }
    .team-bio-img img{
        height: 50%;
        width: 80%;
    }
    .team-bio-content div{
        padding-inline-end: 10px;
    }
    .team-bio-content-contianer{
        padding: 20px 20px;
    }
}
