.post-wrapper{
    max-width: 400px;
    min-width: 400px;
    margin: 40px 20px ;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 2px 11px 0px rgba(133,128,128,0.79);
    -webkit-box-shadow: 0px 2px 11px 0px rgba(133,128,128,0.79);
    -moz-box-shadow: 0px 2px 11px 0px rgba(133,128,128,0.79);
    color: #555961;
}
.post-img-wrapper{
    height: 250px;
    width: 100%;
}
.post-img-wrapper img{
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 4px 4px -3px rgba(133,128,128,0.76);
    -webkit-box-shadow: 0px 4px 4px -3px rgba(133,128,128,0.76);
    -moz-box-shadow: 0px 4px 4px -3px rgba(133,128,128,0.76);
}
.author-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#555961;
    font-size: 17px;
    padding-top: 15px;
}
.author-wrapper p{
    margin: 0;
}
.post-author span{
    font-weight: 700;
}
.post-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}
.post-content-wrapper{
    width: 100%;
    padding: 10px 20px;
    background: #FFFFFF;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.post-title{
    padding-top:10px;
    color:#555961;
    font-size: 20px;
}
.post-header{
    height:130px;
}
.post-body{
    font-size: 17px;
    height: 180px;
}
.post-footer p{
    margin: 0;
}
@media screen and (max-width:1750px) {
    .post-wrapper{
        max-width: 350px;
        min-width: 350px;
    }
}
@media screen and (max-width:1300px) {

}
@media screen and (max-width:1260px) {
    .post-wrapper{
        max-width: 300px;
        min-width: 300px;
    }
    .post-body{
        height: 250px;
    }
}
@media screen and (max-width:1000px) {
    .post-wrapper{
        max-width: 300px;
        min-width: 300px;
    }
}
@media screen and (max-width:680px) {
    .post-wrapper{
        max-width: 300px;
        min-width: 300px;
    }
    .author-wrapper,
    .post-footer{
        font-size: 15px;
    }
}
@media screen and (max-width:400px) {
    .post-wrapper{
        max-width: 280px;
        min-width: 280px;
    }
    .post-title{
        font-size: 18px;
    }
    
    
}