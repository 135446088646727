.landing-page-about{
    position: relative;
    margin-top: 80px;
}
.landing-page-about-body{
    padding: 50px 0px;
    background-color:#1E52AE;
}
.top-container{
    height: 50px;
    background: #ECF0FD;
    position: relative;;
    border-radius:50% 50% 50% 50% / 0% 0% 100% 100%; 
}
.bottom-container{
    height: 50px;
    background: #ECF0FD;
    border-radius:50% 50% 50% 50% / 100% 100% 0% 0% ;
}
.about1{
    display: flex;
    opacity: 0;
    transform: scale(50%);
    transition: 3s;
}
.about1-zoom{
    display: flex;
    opacity: 1;
    transform: scale(100%);
    transition: 3s;
}
.about-image1{
    position: relative;
    width:45%;
}
.about-image1 img,
.about-image2 img{
    object-fit: cover;
}
.about-image2{
    position: relative;
    width:45%;
    order: 2;
}
.image1{
    position: relative;
    top:0px;
    right:0px;
    width:340px;
    height:340px;
    float:right;
    z-index: 0;
}
.small-circle{
    width:40px;
    height:40px;
    position: absolute;
    top:60px;
    right:370px;
}
.big-circle{
    width: 240px;
    height:240px;
    position: absolute;
    top:120px;
    right: 180px;
}
.vertical-dots{
    width:120px;
    height:300px;
    position: absolute;
    bottom:0px;
    right:280px;
}
.about-content1{
    width:55%;
}
.about-content2{
    width:55%;
    order: 1;
}
.about2{
    display: flex;
    flex-direction: row;
    opacity: 0;
    transform: scale(50%);
    transition: 3s;
}
.about2-zoom{
    display: flex;
    flex-direction: row;
    opacity: 1;
    transform: scale(100%);
    transition: 3s;
}
.inner-about-content1{
    color:#FFFFFF;
    display: inline-block;
    padding-right: 40%;
    padding-top: 30px;
    padding-left: 30px;
}
.inner-about-content2{
    color:#FFFFFF;
    display: inline-block;
    padding-left: 50%;
    padding-top: 30px;
    padding-right: 10px;
    text-align: right;
}
.inner-about-content1 h1{
    font-size: 25px;
}
.inner-about-content2 h1{
    font-size: 25px;
}
.inner-about-content1 p,
.inner-about-content2 p
{
    font-size: 17px;
    line-height: 2;
    text-align: justify;
}
.read-more-btn{
    background-color:#FFE919 ;
    border-radius: 30px;
    color: #0F2D62;
    padding: 20px 50px;
    border-style: none;
}
.image2{
    position: relative;
    width:340px;
    height:340px;
    float:left;
    z-index: 5;
}
.small-circle2{
    width:40px;
    height:40px;
    position: absolute;
    top:0px;
    left:100px;
    z-index: 1;
}
.big-circle2{
    width: 210px;
    height:210px;
    position: absolute;
    top:-90px;
    left:260px;
}
.horizontal-dots{
    width:230px;
    height:140px;
    position: absolute;
    top:80px;
    left:250px;
}
.about-sponsors{
    background-color: #FFFFFF;
    width: 100%;
    overflow-x: hidden;
}
.about-team{
    background-image:url("../../assets//images/NIMBUS_BACKGROUND_LOGO.png");
    background-repeat: repeat;
    background-size: 110px 110px;
    text-align: center;
    background-color: #FFFFFF;
    position: relative;
    padding: 90px 0px 90px;
}
.background-overlay{
    background: white;
    width: 100%;
    height: 100%;
    opacity: .7;
    position: absolute;
    top:0px;
}
.about-team-header{
    text-align: center;
    padding:50px 50px 20px 50px;
    position: relative;
    z-index: 6;
}
.about-team-header div{
    width:40%;
    margin:0 auto;
}
.about-team-header h1{
    color:#272D4E;
    font-weight: 600;
    margin: 0px;
    font-size: 40px;
}
.about-team-header p{
    color:#94A2B3;
    font-size: 15px;
}
.team-image-wrapper{
    position: relative;
    width:95%;
    padding-bottom:20px;
    margin:auto;
}
.team-image-wrapper div{
    width: 90%;
    margin: 0 auto;
}
.team-image-wrapper img{
    object-fit: contain;
}
.team-image{
    width: 1400px;
    position: relative;
    text-align: center;
    z-index: 6;
}
.circle1{
    position: absolute;
    top:0px;
    left:90px;
    height:50px;
    width:50px;
}
.circle2{
    position: absolute;
    left:160px;
    top:90px;
    height:25px;
    width:25px;
    font-weight: bolder;
}
.circle3{
    position: absolute;
    top:-40px;
    right:90px;
    height:40px;
    width:40px;
}
.red-dots{
    position: absolute;
    bottom:50px;
    right:30px;
    height:150px;
    width:400px;
}
.yellow-dots{
    position: absolute;
    bottom:0px;
    left:70px;
    height:150px;
    width:210px;
}
.about-team-btn{
    background-color:#16A4DE;
    color:#FFFFFF;
    padding:15px 40px;
    border-radius: 30px;
}

/* sponsor */
.about-sponsors{
    padding-top: 90px;
    background: linear-gradient(180deg, rgba(236, 240, 253, 0) 0%, rgba(236, 240, 253, 0.53) 14.32%, #ECF0FD 45.83%, rgba(236, 240, 253, 0.43) 84.33%, rgba(236, 240, 253, 0) 100%);

}
.about-sponsors-header{
    text-align: center;
    width: 30%;
    margin:auto;
    padding: 0px 80px 50px;
}
.about-sponsors-header h1{
    font-size: 40px;
    font-weight: 600;
    color:#272D4E;
}

.about-sponsors-text{
    padding: 50px;
    position: relative; 
    text-align: center;
    width: 60%;
    margin: auto;
}
.about-sponsors-text img{
    position: absolute;
    right:130px;
    width:200px;
    object-fit: cover;
}
.about-sponsors-inner-text-container{
    background-image: url("../../assets/images/quote.png");
    background-repeat: no-repeat;
    align-items: center;
    background-size: 40% 50%;
    background-position: 50% 20%;
    justify-content: center;
    padding: 50px 50px 80px;
    width:100%;
    height: 100%;
    transition: 1s ease;
}
.about-sponsors-inner-text-container.Active{
    background-image: url("../../assets/images/quote.png");
    background-repeat: no-repeat;
    align-items: center;
    background-size: 40% 50%;
    background-position: 50% 20%;
    justify-content: center;
    padding: 50px 50px 80px;
    width:100%;
    height: 100%;
    transition-duration: 1s;
    transform: scale(1.08);
}
.about-sponsors-text-container1 p{
    line-height: 1.5;
    padding: 0px 30px;
}
.about-sponsors-text-container1{
    text-align: center;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    border-radius: 20px;   
    color: #94A2B3;
    position: absolute;
    left: 0;
    right: 0;
    width:470px;
    top: 10px;
}
.about-sponsors-text-container2{
    width:480px; ;
    top: 5px;
    text-align: center;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    border-radius: 20px;   
    color: #94A2B3;
    position: absolute;
    left: 0;
    right: 0;
}
.about-sponsors-text-container3{
    width:490px;
    top: 0px;
    text-align: center;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    border-radius: 20px;   
    color: #94A2B3;
    position: absolute;
    left: 0;
    right: 0;
}
.about-sponsors-text-container4{
    width:500px;
    top: -5px;
    text-align: center;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    border-radius: 20px;   
    color: #94A2B3;
    position: absolute;
    left: 0;
    right: 0;
}
.brand-container{
    margin-top: 250px;
    overflow:hidden;
    text-align: center;
    position: relative;
}
.inner-brand-container{
    padding-bottom: 30px;
    transition:transform 300ms cubic-bezier(.455,.03,.515,.955);
}
.brand-inner-wrapper img{
    height: 70px;
    margin:auto;
    width: 100px;
}
.brand-inner-wrapper{
    text-align: center;
    width:95%;
    border-radius: 8px;
    margin: 5px;
    padding:10px;
    
}
.brand-wrapper{
    margin-bottom: 30px;
}
.brand-navigation-container{
    background: #FFFFFF;
    padding-top: 120px;
}
.brand-navigation-inner-container{
    display: inline;
}
.brand-navigation-inner-container button{
    padding: 10px 0px;
    border: hidden;
    background-color: transparent;
}
.left-brand-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    bottom:-15%;
    right:50%;
    font-size: 30px;
    margin-right: 5px;
    cursor: pointer;
}
.right-brand-nav{
    position:absolute;
    bottom: -15%;
    left:50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}
.left-brand-nav:hover{
    transform: scale(1.05);
}
.right-brand-nav:hover{
    transform: scale(1.05);
}

@media screen and (max-width: 1720px){
    .about2{
        margin-top: 20px;
    }
    .about-content2,
    .about-content21,
    .about-image1,
    .about-image2{
        width:50%;
    }
    .about-sponsors-header{
        padding:0px 40px 50px;
    }
    }

@media screen and (max-width: 1560px){
    .about2{
        margin-top: 40px;
    }
    .about-content2,
    .about-image2{
        width:50%;
    }
    .inner-about-content1{
        padding-right: 20%;
    }
    .inner-about-content2{
        padding-left:20%;
    }
    .team-image{
        width: 100%;
    }
    .imageBackground{
        background-size: 100% 100%;
    }
    .circle2{
        display: none;
    }
    .about-sponsors-header{
        width: 45%;
    }
}
    @media screen and (max-width: 1300px){
        .about2{
            margin-top: 50px;
        }
        .about-team-header div{
            width: 60%;
        }
        .red-dots{
            width: 320px;
            height: 150px;
        }
        .yellow-dots{
            width: 150px;
            height: 130px;
        }
        .about-sponsors-text-container2{
            width: 400px;
        }
        .about-sponsors-text-container3{
            width: 410px;
        }
        .about-sponsors-text-container4{
            width: 420px;
        }
        .about-sponsors-text img{
            right:-40px;
        }
    }
    @media screen and (max-width: 1100px){
        .red-dots,
        .circle3,
        .circle2,
        .circle1,
        .yellow-dots{
            display: none;
        }
        .about-sponsors-header{
            width: 60%;
        }
        .about1{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transform: scale(85%);
            transition: 2s;
            padding-bottom: 50px;
        }
        .about2{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transform: scale(85%);
            transition: 2s;
        }
        .about1-zoom{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 1;
            transform: scale(100%);
            transition: 2s;
            padding-bottom: 50px;
        }
        .about2-zoom{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 1;
            transform: scale(100%);
            transition: 2s;
        }
        .inner-about-content1,
        .inner-about-content2
        {
            padding: 0px;
            text-align: center;
        }
        .about-image1,
        .about-image2{
            width: unset
        }
        .about-image2{
            width: unset;
            order: 1;
        }
        .about-content2{
            order: 2;
        }
        .image1,
        .image2{
            top:0px;
        }
        .landing-page-about-body{
            padding: 70px 0px;
        }
        

    }
    @media screen and (max-width: 760px){
        .about-sponsors-header{
            width: 70%;
        }
        .about-sponsors-text-container2{
            width: 350px;
        }
        .about-sponsors-text-container3{
            width: 360px;
        }
        .about-sponsors-text-container4{
            width: 370px;
        }
        .about-sponsors-text img{
            right:-40px;
            top:20px;
        }
        .about-content2,
        .about-content1{
            width: 80%;
        } 
        .big-circle2{
            top:-40px;
        }
        .small-circle2 {
            top:-3px;
        }
       
        .about-team-header div{
            width: 70%;
        }
        .circle1{
            top:-30px;
            left:40px;
            width: 30px;
            height: 30px;
        }
        .circle3{
            width: 20px;
            height: 20px;
            top:-50px;
            right:50px;
        }
        .red-dots{
            width: 250px;
            height: 80px;
        }
        .about-team-btn{
            padding:10px 20px;
        }
        .yellow-dots{
        display: none;
        }
        .about-sponsors-text{
            width: 100%;
        }
        .about-sponsors-header h1 {
            font-size: 35px;
        }
        .about-sponsors-inner-text-container{
            padding: 50px;
        }

    }


@media screen and (max-width: 600px){
    .about-team-header h1{
        font-size: 26px;
    }
    .about-team-header p{
        font-size: 12px;
        padding-top: 10px;
    }
    .about-team-header div{
        width: 75%;
    }
    .about-team-header{
        padding: 0px 20px 20px;

    }
    .red-dots{
        width: 150px;
        height: 80px;
    }
    .about-sponsors-header{
        width: 80%;
    }
    .image1,
    .image2{
        width: 300px;
        height: 300px;
    }
    .big-circle{
        width: 200px;
        height: 200px;
    }
    .big-circle2{
        width: 200px;
        height: 200px;
        left: 100px;
    }
    .horizontal-dots{
        left: 0px;
    }
    .small-circle2{
        display: none;
    }
}
@media screen and (max-width: 500px){
    .about-sponsors-header{
        width: 90%;
    }
    .about-sponsors-header h1 {
        font-size: 22px;
    }
    .red-dots{
        width: 100px;
        height: 70px;
        bottom: 20px;
    }
    .about-sponsors-text-container2{
        width: 250px;
    }
    .about-sponsors-text-container3{
        width: 250px;
    }
    .about-sponsors-text-container4{
        width: 250px;
    }
    .about-sponsors-inner-text-container{
        padding: 30px 20px 30px;
    }
    .brand-container{
        margin-top: 150px;
    }
    .about-sponsors-header {
        width: 100%;
    }
    .about-team-header div{
        width: 85%;
    }
}