.gallery-section-nav{
    text-align: center;
    padding-top: 30px;
    margin-bottom: 60px;
}
.gallery-section-inner-nav{
    display: inline;
    margin: auto;
}
.gallery-nav-wraper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin: auto;
}
.gallery-nav-wraper div{
    color: #272D4E;
    width: 25%;
}
.gallery-nav-wraper a{
    color: #272D4E;
    font-weight: 600;
    font-size: 20px;
    text-decoration: none;
}
.gallery-nav-wraper a:hover{
    color:#4286FF;
}
.gallery-nav-line{
    width:30%;
    margin: auto;
    border-radius: 40px;
    background-color: #DFEAEE;
    height: 5px;
    display: flex;
}
.all-section{
    height: 100%;
    width: 25%;
    background:#4286FF ;
    border-radius: 50px;
}
.upload-box{
    width: 550px;
    padding: 50px 0px;
    /* border: 2px dashed #272D4E; */
    margin: auto;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 2px;  

}
.inner-upload-box{
    width: 50%;
    margin: auto;
    position: relative;
    z-index:1;
    text-align: center;
    color:#272D4E;
    
}
.upload-box::after{
    content: "";
    position: absolute;

    background:rgba(39, 45, 78, 0.05);
    width: 100%;
    height: 100%;
    top:0px;
}
.inner-upload-box div{
    display: flex;
    border: 3px solid #272D4E;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    box-shadow: 5px 6px 5px -3px #888888;
    cursor: pointer;
}
.inner-upload-box div:hover{
    background-color:#272D4E;
    color:#FFFFFF;
}
.inner-upload-box input{
    display: none;
}
.inner-upload-box p{
    margin: 0px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
}
.inner-upload-box h1{
    margin: 0px;
    font-size: 25px;
    font-weight: 800;
    padding-left: 5px;
}
.inner-upload-box i{
    font-size: 30px;
}

.upload-gallery-text{
    width: 80%;
    margin: auto;
    padding-top: 100px;
    color:#272D4E;
}
.upload-gallery-text h1{
    font-size: 30px;
    font-weight: 700;
}
.upload-gallery-text span{
    font-size: 20px;
    font-weight: 500;
}
.selected-img-details,
.selected-img-wrapper{
    width: 50%;
}
.selected-inner-img-wrapper{
    width:80%;
    margin: auto;
    text-align: center;
    height: 400px;
}
.selected-inner-img-details{
    width: 80%;
    margin: auto;
}
.selected-img-inner-container{
    width: 75%;
    display: flex;
    margin: auto;
    justify-content:space-evenly;
}
.img-input{
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 50px;
    border:2px solid #272D4E;
    margin:5px 0px  20px 0px;
    padding-left: 10px;
}
.img-input:focus{
    outline: none;
}
.selected-inner-img-details label{
    font-size: 20px;
    padding:10px;
    margin-bottom: 5px;
}
.selected-inner-img-details button{
    border-radius: 50px;
    border-style: none;
    padding: 15px 40px;
    font-weight: 200;
    margin-right: 10px;
    cursor: pointer;
}

.img-save-btn{
    background:#272D4E;
    color:#FFFFFF;
}
.img-save-btn:hover{
    background:transparent;
    color:#272D4E;
    border:2px solid #272D4E;
}
.img-cancel-btn{
    color:#272D4E;
    
}
.img-cancel-btn:hover{
    color:#272D4E;
    border:2px solid #272D4E;
}
.selected-img-wrapper img{
    width: 400px;
    height:400px;
}
.edit-img-container{
    display: flex;
    justify-content: center;
    width: 70%;
    margin:auto;
    padding-top: 10px;
}
.edit-img-container div{
    display: flex;
    justify-content: center;
    width: 25%;
    cursor: pointer;
}
.edit-img-container p{
    margin: 0px;
    font-size: 13px;
    padding-left: 3px;
}
.edit-img-container input{
    display: none;
}
.back-drop{
    width: 100%;
    height: 100vh;
    background-color: black;
    position: fixed;
    top:0px;
    left:0px;
    z-index: 60;
    opacity: .8;
}

.delete-img-alert{
    position: fixed;
    width: 500px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -250px; 
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 65;
    display: flex;
    justify-content: center;
}
.delete-img-alert-wrapper{
    width: 80%;
    margin:auto;
    text-align: center;
   
}
.delete-img-alert-btn button{
    border-radius: 30px;
    padding: 10px 20px;
    border-style: none;
    margin: 20px;
}
.yes-btn{
    background: #272D4E;
    color: #FFFFFF;
}
.ok-btn{
    background: #272D4E;
    color: #FFFFFF;
}
.activeSection{
    border-bottom: 3px solid #16A4DE; 
    border-radius: 3px;
    cursor: pointer;
}
@media screen and (max-width: 1500px) {
    .gallery-nav-wraper{
        width: 50%;
    }
}
@media screen and (max-width: 850px) {
    .gallery-nav-wraper{
        width: 65%;
    }
    .main-gallery-header h1 {
        font-size: 35px;
    }
    .upload-box{
        width:450px
    }
    .main-gallery-container{
        background-size:400px 400px;
    }
    .upload-gallery-text h1 {
        font-size: 25px;
    }
}
@media screen and (max-width: 650px) {
    .gallery-nav-wraper a {
        font-size: 15px;
    }
    .upload-gallery-text{
        padding-top: 40px;
    }
    .upload-gallery-text h1 {
        font-size: 20px;
    }
    .gallery-nav-wraper{
        width: 80%;
    }
    .upload-box{
        width:400px;
    }
    .inner-upload-box{
        width: 70%;
    }
    .delete-img-alert {
        width:70%;
        margin-left: -35%;
    }
}
@media screen and (max-width: 470px) {
    .upload-box{
        padding: 35px 0px;
        width: 320px;
    }
    .inner-upload-box{
        width: 70%;
    }
    .gallery-nav-wraper{
        width: 90%;
    }
    .inner-upload-box h1,
    .inner-upload-box i{
        font-size: 20px;
    }
    .upload-gallery-text h1,
    .upload-gallery-text span{
        font-size: 15px;
    }
    .delete-img-alert {
        width:90%;
        margin-left: -45%;
    }
}