.charity-container{
    background: #1E52AE;
    position: relative;
    overflow: hidden;
}
.charity-body-container{
    display: flex;
    position: relative;
    padding:50px 0px;
    opacity: 0;
    transition: 3s;
    transform: scale(50%);
}
.charity-body-container-zoom{
    opacity: 1;
    transition: 3s;
    transform: scale(100%);
}
.charity-header-container {
    position: relative;
    text-align: center;
    padding-top:90px;
}
.charity-header-container h1{
    position: absolute;
    padding-top: 0px;
    color:#FFE919;
    margin:0px;
    top:-25px;
    left: -10px;
    font-size: 38px;
}

.header-background{
    position: relative;
    width: 380px;
    height: 15px;
    background-color: #0F2D62;
    margin:0 auto;
}
.background-image1{
    width:180px;
    height:90px;
    right:220px;
    top:20px;
    position: absolute;
}
.background-image2{
    position: absolute;
    bottom: 20px;
    width:180px;
    height:90px;
    right:10px;
}
.background-image3{
    position: absolute;
    left:100px;
    width:200px;
    height:120px;
    top:50px;

}
.background-image4{
    position: absolute;
    left:190px;
    width:20px;
    height:20px;
    top:20px;

}
.background-image5{
    position: absolute;
    left:110px;
    width:40px;
    height:40px;
    top:-50px;
}
.background-image6{
    position: absolute;
    left:215px;
    width:180px;
    height:180px;
    top:170px;

}
.background-image7{
    position: absolute;
    left:180px;
    width:300px;
    height:150px;
    bottom:115px;
}
.charity-image-container img{
    object-fit: contain;
}
.charity-image-container{
    width: 45%;
    position: relative;
    padding: 10px 20px 40px 0px;
}
.charity-image{
    position: absolute;
    width:400px;
    height:400px;
    right: 40px;
    display: inline-block;
    object-fit: cover !important;
}
.charity-image-background{
   margin-top: 65px;
   margin-left: 65px;
    width:350px;
    height:350px;
    float: right;
    z-index: 1;
}
.charity-body-container p{
    width: 70%;
    color:#FFFFFF;
    padding:0px 20px;
    font-size: 17px;
    line-height: 2;
    text-align: justify;
    margin-bottom: 30px;
}
.charity-content-container{
    padding:0px 100px 40px 5px;
    width: 55%;
}
.Charity-para{
    line-height: 1.5;
    margin: 0px;
}
.charity-btn-wrapper{
    position: relative;
    padding: 30px 0px 100px 0px;
}
.charity-btn{
    position: absolute;
    border-radius: 30px;
    background-color: #FFE919;
    padding: 15px 40px;
    left:40%;
    border-style: none;
}
@media screen and (max-width:1600px) {
    .charity-content-container {
        padding: 0px 50px 40px 5px;
    }
}
@media screen and (max-width:1200px) {
    .background-image7{
        display: none;
    }
    .background-image1{
        right:50px;
    }
    .charity-body-container p{
        width: 90%;
    }
}
@media screen and (max-width:1000px) {
    .charity-body-container p{
        width: 100%;
    }
    .charity-body-container{
        display: block;
    }
    .charity-content-container{
        width: 70%;
        margin: auto;
        padding:0px;
        text-align: center;
    }
    .charity-image-container{
        width: 70%;
        margin: auto;
        text-align: center;
    }
    .charity-image-background{
        float: unset;
        position: absolute;
        left: 50%;
        top:50%;
        margin-top: -150px;
        margin-left: -120px;
    }
    .charity-image{
        position: relative;
        z-index: 2;
        right: unset;
        width: 500px;
    }
    .background-image5,
    .background-image4,
    .background-image2{
        display: none;
    }
    .background-image3{
        left:-20px;
    }
    .background-image6{
        left:30px;
    }
    .background-image1{
        right:-70px;
    }
    .charity-btn{
        width: 200px;
        left: 50%;
        margin-left: -80px;
    }
}
@media screen and (max-width:800px) {
    .charity-content-container{
        width: 80%;
        padding-top: 20px;
    }
    .charity-image-container{
        width: 80%;
    }
    .header-background{
        width: 70%;
    }
    .background-image1{
        right:-100px;
    }
    .charity-image{
        height: 300px;
    }
    .charity-image-background{
        width: 270px;
        height: 270px;
        margin-top: -120px;
        margin-left: -120px;
    }
    .background-image6{
        width: 150px;
        height: 150px;
    }
    .charity-header-container h1{
        width: 100%;
        font-size: 34px;
    }
    .charity-body-container p{
        padding: 0px;
        line-height: 2;
    }
}
@media screen and (max-width:600px) {
    .charity-image-container{
        width: 100%;
    }
    .charity-content-container{
        width: 80%;
        padding:10px 0px;
    }
}
@media screen and (max-width:600px) {
    .charity-image{
        width: 70%;
        height: 200px;
    }
    .charity-image-container{
        padding: 10px 0px 40px 0px;
    }
    .charity-image-background{
        width: 150px;
        height: 150px;
        margin-left: -50px;
        margin-top: -50px;
    }
    .charity-header-container h1 {
        font-size: 25px;
        top:-15px;
    }
    .charity-body-container{
        padding-top: 20px;
    }
    .background-image6{
        width: 80px;
        height: 80px;
    }
    .background-image3{
        width: 100px;
        height: 80px;
    }
      .charity-content-container{
        width: 80%;
      }
      .header-background{
          width: 90%;
      }
      .charity-btn{
        width: 150px;
        height: 45px;
        left: 50%;
        margin-left: -75px;
        padding:0px;
    }
}
@media screen and (max-width:400px) {
    .charity-header-container h1 {
        font-size: 22px;
    }
}