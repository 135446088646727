.main-gallery-container{
    width: 100%;
    background: linear-gradient(180deg, rgba(229, 239, 255,1) 40%, rgba(229, 239, 255,.26) 55%,rgba(229, 239, 255, 0) 100%);
    background-image: url("../../assets/images/NIMBUS_BACKGROUND_LOGO.png");
    background-size:800px 600px;
    background-repeat:no-repeat; 
    background-position: 50% 0%;
    position: relative;
}
.main-gallery-header{
    padding: 30px 0px;
    text-align: center;
}
.main-gallery-header h1{
    font-size:45px;
    color:#272D4E;
    font-weight:700;
}
.gallery1-slider-container{
    position: relative;
    width: 100%;
    min-height: 500px;
}

.gallery1-slider-img p{
    font-size: 20px;
    color:#272D4E ;
    font-weight: 600;
}
.gallery-slider{
    width:80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow:hidden;
    margin-top: 50px;
}
.gallery1-slider-img{
    width: 33.3%;
    text-align: center;
    padding: 10px;
    position: relative;
}
.gallery1-slider-img img{
    width:90%;
}
.gallery1-slider-img i{
    position:absolute;
    right: 40px;
    top: 20px;
    color: #16A4DE;
    font-size: 30px;
}
.main-gallery-nav{
    padding:70px 0px 40px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery-nav-wraper a{
    color: #272D4E !important;
}
.image-not-found{
    width: 250px;
    display: block;
    margin: 60px auto;
}
@media screen and (max-width: 1024px) {
    .gallery1-slider-img{
        max-width: 33.3%;
        min-width: 33.3%;
    }
    .main-gallery-container{
        background-size:600px 400px;
    }
}   
@media screen and (max-width: 768px) {
    .gallery1-slider-img{
        max-width: 50%;
        min-width: 50%;
    }
    .main-gallery-container{
        background-size:400px 400px;
    }
}   

@media screen and (max-width: 600px) {
    .gallery-slider{
        width: 90%;
    }
    .gallery1-slider-img img{
        max-width: 200px;
        min-width: 200px;
    }
}
@media screen and (max-width: 470px) {
    .gallery-slider{
        width: 80%;
    }
    .gallery1-slider-img{
        min-width: 100%;
        max-width: 100%;
    }
    .gallery1-slider-img img{
        max-width: 100%;
        min-width: 100%;
    }
}