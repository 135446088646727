.edit-team-profile-btn{
    width: 70%;
    margin: auto;
    position: relative;
    padding: 20px 0px;
}
.edit-team-profile-btn button{
    position: absolute;
    right:20px;
    border-style: none;
    border-radius: 60px;
    border:2px solid #272D4E;
    color: #272D4E;
    background: transparent;
    padding: 10px 15px;
    font-size: 17px;
    font-weight: 600;
}
.edit-team-profile-btn button:hover{
    background:#272D4E;
    border-style: none;
    color:#FFFFFF;
}
.edit-team-profile-btn span{
    position: relative;
    top:-3px;
    padding-right: 5px;
}
