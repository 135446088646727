.signin-container{
    background-color: #4286FF;
}
.signin-header{
    padding-top: 10px;
}
.signin-header div{
    text-align: center;
    width: 80%;
    margin: auto;
    position: relative;
}
#inner-body{
    height: 440px;
}
.signin-header-logo{
    width:120px;
    height: 100px;
}
.signin-body{
    padding-top: 40px;
    position: relative;
}
.signin-inner-body{
    width: 80%;
    margin: auto;
    position: relative;
    z-index: 0;
}
.signin-form-container{
    display: flex;
    width: 550px;
    margin: auto;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 20px;
    padding:30px 0px;
    position: relative;
    z-index: 1;
}
.signin-input-wrapper{
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 20px 0px;
}
.signin-input-wrapper p{
    color: #0F2D62;
    font-size: 28px;
    margin: 0px;
    padding-bottom: 20px;
    font-weight: 700;
}
.signin-input-wrapper input,
.signin-input-wrapper button{
    width: 100%;
    border-radius: 60px;
    border: 2px solid #FFE919;
    margin: 10px 0px;
    padding:18px;
}
.signin-input-wrapper button{
    background: #FFE919;
    font-size:18px;
    font-weight: 600;
    color: #0F2D62;
    cursor: pointer;
}
.siginup-input:focus{
    outline: none;
}
.signin-form{
    width: 100%;
}
.signin-backgroundImg{
    width: 430px;
    position: absolute;
    left:30%;
    top:-30px;
    height: 300px;
    background: #FFE919;
    border-radius: 0px 185px 0px 0px;
    transform: matrix(-0.95, -0.32, -0.33, 0.95, 0, 0);
}
.signin-backgroundCircle{
    border-radius: 50%;
    width: 250px;
    height: 250px;
    border: 3px solid #FFE919;
    position: absolute;
    right:20%;
    bottom: -15%;
}
.signin-bottom-text{
    text-align: center;
    color: #FFFFFF;
    padding-top: 20px;
}
.signin-bottom-text p{
   margin-bottom: 0px;
}
.siginin-forgot-password{
    margin: 0px;
    padding-top: 5px;
}
.signin-bottom-text a{
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: underline;
}
.signin-yellow-dots{
    width:220px;
    height: 110px;
    position: absolute;
}
.signin-header .signin-yellow-dots{
    right:200px;
}
.signin-body .signin-yellow-dots{
    left:50px;
    top:150px;
}
.signin-footer button{
    color:#FFE919;
    border-radius: 60px;
    border:2px solid #FFE919;
    padding: 10px;
    font-size: 18px;
    background: transparent;
    position: absolute;
    right:190px;
    bottom: 10px;
}
.signin-footer{
    overflow: hidden;
    position: relative;
    padding: 90px 0px;
}
.signin-footer img{
    right: -2px;
    bottom: 20px;
}
.sigup-input-wrapper{
    text-align: left;
} 
.sigup-input-wrapper label{
    color: #57584E;
    font-size:22px;
    font-weight: bold;
}
.signup-bottom-text{
    padding-top: 20px;
    text-align: center;
    width: 80%;
    margin:auto;
    font-size: 18px;
    font-weight: 400;
    color:#B5B5B5 ;

}
.signup-bottom-text a{
    color: #FFFFFF;
}
.signup-bottom-text p{
    margin-bottom: 20px;
    color: #B5B5B5;
}
.blank-div{
    background:#1E52AE ;
    height: 550px;
}
p.error-message{
    padding: 10px 10px;
    color:red;
    font-size: 20px;
    background: pink;
    margin: 0px;
    text-align: left;
}
@media screen and (max-width: 1400px) {
    .signin-backgroundCircle{
        right:10%;
    }
    .signin-header .signin-yellow-dots{
        right:50px;
    }
    .signin-backgroundImg{
        left:25%;
    }
}
@media screen and (max-width: 1100px) {
    .signin-backgroundCircle{
        right:0%;
    }
    .signin-header .signin-yellow-dots{
        right:0px;
    }
    .signin-backgroundImg{
        left:15%;
    }
    .signin-footer button{
        right:50px;
    }
}
@media screen and (max-width: 800px) {
    .signin-backgroundCircle,
    .signin-footer img,
    .signin-header .signin-yellow-dots,
    .signin-backgroundImg,
    .signin-body .signin-yellow-dots,
    .signin-yellow-dots{
        display:none
    }
    .signin-header{
        background-color: #1E52AE;
    }
    .signin-body{
        padding-top: 60px;
    }
    .signin-inner-body{
        width: 100%;
        margin:unset
    }
    .signin-form-container{
        width:70%;
    }
    .signin-input-wrapper p {
        font-size: 20px;
    }
    .signin-input-wrapper{
        width: 80%;
    }
    .signin-input-wrapper input,
    .signin-input-wrapper button {
        padding:13px;
    }
    .signin-footer{
        padding:50px 0px;
        text-align: center;
    }
    .signin-footer button {
        position: unset;
        padding:10px 20px;
        font-size: 12px;
    }
    .blank-div{
        background: unset;
    }
    .signin-container{
        padding-bottom: 70px;
    }
    .signup-footer-btn{
        display: none;
    }
}
@media screen and (max-width: 700px) {
    .signin-form-container{
        width:70%;
    }
    .signin-header-logo{
        height: 100px;
        width: 100px;
    }
}
@media screen and (max-width: 500px) {
    .signin-form-container{
        width:90%;
    }
}