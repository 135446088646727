.gallery-container{
    text-align: center;
    padding: 50px 10px 30px 10px;
}
.gallery-inner-container{
    display:flex;
    align-items: center;
    justify-content: center;
}
.gallery-inner-container div{
    /* width: 28%; */
    margin: 20px;
}
.gallery-image{
    width: 100%;
    height: 300px;
    margin: auto;
}
.gallery-slider{
    transform: scale(.5);
    transition: transform 300ms;
}
.gallery-slider-active{
    transform: scale(1.1);
}
.gallery-image-active{
    width: 100%;
    height: 500px;
}
.gallery-header{
    color:#272D4E;
    font-weight: 900;
    padding-bottom: 30px;
}
.image-title{
    color:#272D4E;
    font-weight: 600;
}
.gallery-nav{
    margin-top: 40px;
    display: flex;
    justify-content: center;
}
.gallery-nav div{
    padding:5px;
}
.right-image-nav{
    font-size: 30px;
}
.left-image-nav{
    font-size: 30px;
    font-weight:normal;
    position: relative;
    top:20%;
}
.forward-img-nav{
    position:absolute;
    bottom: -14%;
    left:50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.forward-img-nav:hover{
    transform: scale(1.1);
}
.back-img-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    bottom:-14%;
    right:50%;
    font-size: 30px;
    margin-right: 10px;
}
.back-img-nav:hover{
    transform: scale(1.1);
}
.gallery-image-title{
    font-size: 25px;
    color: #272D4E;
    margin-top: 15px;
}
@media screen and (max-width: 1240px){
    .gallery-image{
        width: 400px;
        height: 300px;
    }
    .gallery-image-active{
        width: 400px;
        height: 500px;
    }
}
@media screen and (max-width: 1000px){
    .gallery-inner-container div{
        margin: 10px;
        width: 30%;
    }
}

@media screen and (max-width: 920px){
    .gallery-image-container-active{
        width: 100%;
    }
    .gallery-slider-active{
        transform: unset;
    }
    .gallery-image{
        height: 250px;
    }
}
@media screen and (max-width: 650px){
    .gallery-image{
        width: 100%;
        height: 300px;
    }
    .gallery-image-active{
        width: 100%;
        height: 500px;
    }
}