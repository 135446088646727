.ContactUs-container{
    background-color: #F6F6F6;
    padding:20px 40px 90px 40px;
    text-align: center;
}
.ContactUs-inner-container{
    width:70%;
    margin:0 auto;
}
.ContactUs-inner-container h1{
    font-weight: 600;
    margin:9px;
}
.ContactUs-inner-container p{
    font-weight: 600;
    margin:5px;
}
.contact-us-para{
    color:#808080;
}
.message-container{
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 30px 0px 50px 0px;
}
.message-container div{
    width:90%;
    margin:0 auto;
}
.contact-input-wrapper{
    text-align: left;
}
.message{
    width: 60%;
    text-align: left;
    font-weight: 600;
}
.contact-input{
    width: 60%;
    margin-top: 20px;
    padding-left: 10px;
    border: 1px solid #C4C4C4;
    font-size: 16px;
    height: 35px;
    border-radius: 10px;
}
.contact-input:focus{
    box-shadow: 0px 0px 11px -1px rgba(22,164,222,0.75);
    -webkit-box-shadow: 0px 0px 11px -1px rgba(22,164,222,0.75);
    -moz-box-shadow: 0px 0px 11px -1px rgba(22,164,222,0.75);
    outline: none;
}
.contact-textarea{
    margin-top: 20px;
    width: 100%;
    font-size: 16px;
    height: 150px;
    padding-left: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}
.contact-textarea:focus{
    box-shadow: 0px 0px 11px -1px rgba(22,164,222,0.75);
    -webkit-box-shadow: 0px 0px 11px -1px rgba(22,164,222,0.75);
    -moz-box-shadow: 0px 0px 11px -1px rgba(22,164,222,0.75);
    outline: none;
}
.message-container button{
    background-color: #16A4DE;
    color: #FFFFFF;
    border-style: none;
    margin-top: 5px;
    border-radius: 20px;
    padding: 10px 30px;
    
}
.message-container button:hover{
    border: 2px solid #16A4DE;
    color: #16A4DE;
    background-color: white;
}
@media screen and (max-width: 1146px){
    .ContactUs-inner-container{
        width: 90%;
    }
}
@media screen and (max-width: 890px){
    .contact-input{
        width: 100%;
    }
    .ContactUs-inner-container h1{
        font-size: 25px;
    }
    .ContactUs-container{
        padding:20px 0px 40px 0px;
    }
    .message{
        width: 100%;
    }
    
}
