@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    .landing-page-height{
        height: calc(100vh - 96px);
    }
    .about-header-alignment{
        text-align:center;
    }
    @media screen and (min-width: 1024px ) {
        .about-header-alignment{
            text-align:left;
        }
    }
  }