.add-team-img img{
    width: 80%;
}
.add-team-img{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.add-team-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-team-content div{
    width: 80%;
    margin: auto;
}
.add-team-form{
    width: 100%;
    height: calc(100% - 80px);
    padding:10px 0px;
    overflow: auto;
}
.add-team-content-contianer{
    display: flex;
    width: 100%;
}
.add-team-content div input,
.add-team-content div select{
    width:100%;
    border-radius: 7px;
    border-style: none;
    border:1px solid black;
    margin-bottom:5px;
}
.add-team-content div textarea{
    width:100%;
    border-radius: 7px;
    border-style: none;
    border:1px solid black
}
.add-team-header{
    background:#849DBE ;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content:end;
    align-items: center;
    padding: 5px 30px;
    top: 0;
    left: 0;
    font-size: 25px;
    position: sticky;
    color: white;
    z-index: 30;
}
.add-team-footer{
    background:#849DBE ;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px;
    bottom: 0;
    left: 0;
    z-index: 30;
    position: fixed;
}
.add-team-footer button{
    color:#FFFFFF ;
    border-radius: 5px;
    border-style: none;
    width: 60px;
    background: #272D4E  ;
}
.add-team-footer button:hover{
    border:1px solid  #272D4E;
    background-color: transparent;
    color:#272D4E;
}

.add-team-img label{
    position: absolute;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px dashed #272D4E;
    padding: 40px;
}
@media screen  and (max-width: 750px){
    .add-team-content-contianer{
        display: block;
    }
}