.footer-card{
    width: 80%;
    display: inline-block;
}
.footer-card div{
    padding: 10px 0px;
}
.footer-card img{
    float: left;
    width:70px;
    height: 100%;
    margin-right: 20px;
}
.footer-card h1{
    margin-right: 60px;
    font-size: 15px;
}
.footer-card p{
    font-size: 10px;
}
.footer-card hr{
    width: 90%;
    border: 1px solid rgba(196, 196, 196, 0.2);
}