.slider-wrapper{
    padding:100px 40px;
}
.slider-container{
    position: relative;
}
.slider-header{
    text-align: center;
    padding-bottom: 40px 40px 0px;
}
.slider-header h1{
    margin:0px;
    font-size: 55px;
    font-weight: 700;
    color:rgba(39, 45, 78, 1);

}

.backward-arrow-container{
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(90deg, rgba(167,199,252,1) 50%, rgba(196,217,253,1) 50%);
    width:40px;
    height:40px;
    top: 50%;
    margin-left: 15px;
    margin-top: -40px;
    left: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 4;
}
.about-backward-arrow-container{
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(90deg, rgba(167,199,252,1) 50%, rgba(196,217,253,1) 50%);
    width:40px;
    height:40px;
    top:50%;
    left: 10px;
    margin-top: -40px;
    margin-left: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 4;
}
.about-forward-arrow-container{
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(90deg, rgba(167,199,252,1) 50%, rgba(196,217,253,1) 50%);
    width:40px;
    height:40px;
    top:50%;
    right: 10px;
    margin-top: -40px;
    margin-right: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}
.forward-arrow-container{
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(90deg, rgba(167,199,252,1) 50%, rgba(196,217,253,1) 50%);
    width:40px;
    height:40px;
    top:50%;
    right: 10px;
    margin-top: -40px;
    margin-right: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}
   
.image-nav{
    position: relative;
    top:-3px;
    font-size: 25px;
    color:rgba(22, 164, 222, 1);
    cursor: pointer;
}
.image-wrapper{
    display: flex;
    justify-content: center;
    overflow-x: visible;
}
.about-image-inner-wrapper{
    width:60%;
    padding:0px 40px;
}
.image-inner-wrapper{
    padding:20px 20px;
}
.slick-slide{
    width:80px;
}
.about-images{
    margin: 0 auto;
    border-radius: 20px;
    width: 150px;
    height:150px;
}
.images{
    border-radius: 20px;
    margin: 0 auto;
    width: 330px;
    height:250px;
    cursor: pointer;
}
.images:hover{
    transform: scale(1.05);
    opacity: .6;
}
.about-content-wrapper{
    padding-top:20px;
}
.about-content-wrapper p{
    text-align: center;
    font-size: 15px;
    color:#94A2B3;
}
.about-content-wrapper h1{
    text-align: center;
    color:#272D4E;
    font-weight: Bold;
    font-size: 30px;
}
.content-wrapper{
    padding-top:20px;
    width: 100%;
}
.content-wrapper h1{
    text-align: center;
    color:#272D4E;
    font-weight: Bold;
    font-size: 30px;
}
.content-wrapper p{
    color:#94A2B3;
}
.content-wrapper a{
    color:#299EF3;
}
.content-wrapper span{
    position: relative;
    top:4px;
}
@media screen and (max-width: 1400px){
    .backward-arrow-container{
        left:10px;
    }
    .forward-arrow-container{
        right:10px;
    }
    
}

@media screen and (max-width: 980px){
    .slider-header h1 {
        font-size: 40px;
    }
    .backward-arrow-container{
        left:10px;
    }
    .image-inner-wrapper{
        padding:20px 20px;
    }
    .forward-arrow-container{
        right:10px;
    }

}
@media screen and (max-width: 760px){
    .slider-header{
        padding-bottom: 20px;
    }
    .forward-arrow-container {
        width:30px;
        height: 30px;
        margin-right: 10px;
    }
    .backward-arrow-container{
         margin-left: 10px;
        width:30px;
        height: 30px;
    }
    .image-nav{
        font-size: 25px;
    }
    .content-wrapper p{
        width: 95%;
    }
    .content-wrapper h1{
        text-align: center;
        font-size: 20px;
        margin: 0px;
    }
    .slider-header h1 {
        font-size: 28px;
    }
    .slider-wrapper{
        padding:70px 0px;
    }
    .images{
        width: 280px;
    }
    
}

