
.footer-head-container{
    background-color: #2260CD;
    text-align: center;
    color: #fff;
    padding: 30px;
}
.footer-head-inner-container{
    display: inline-block;
}
.footer-head-text{
    font-weight: bold;
    display: inline-block;
    text-align: center;
}
.contact-wrapper{
    display:  inline-block;
    margin-top: 20px;
}
.contact-wrapper-link{
    text-decoration: none;
    color:white;
    margin-top: 5px;
    margin-left: 25px;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px 20px;
}
.contact-wrapper-link:hover{
    background-color: #FFFFFF;
    color:#2260CD

}
.footer-body{
    position: relative;
    padding:50px 70px 0px;
    background-color: #1E52AE;
    color:#FFFFFF;
}
.footer-body div{
    text-align: center;
}
.footer-body p{
    margin-bottom: 30px;
}
.footer-body img{
    width: 100px;
    height: 80px;
}
.footer-body hr{
    width:70%;
    margin-top: 10px;
    border: .5px solid #9A7DFF; 
}

.footer-contact,
.footer-bottom{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 auto;
}
.footer-contact div{
    display: flex;
    align-items: center;
    padding: 30px;
}
.footer-bottom p{
    margin:0px;
    font-size: 13px;
}
.footer-bottom div{
    padding: 10px 30px 10px 30px;
}
.footer-contact i{
    padding-right: 5px;
}
.footer-icon{
    text-align: center;
    width:20%;
}
.footer-text{
    padding-left: 30px;
    text-align: center;
    font-weight:bolder;
    width:20%;
}
.footer-icon-wrapper{
    padding: 10px 0px;
    text-align: center;
    width:100%;
    margin: auto;
}
.footer-icon-wrapper i{
    padding:0px 15px;
    cursor: pointer;
}
.footer-icon i{
    padding:0px 15px;
}
.footer-head-container{
   position: relative; 
}
.footer-head-inner-container i{
    font-size: 40px;
    color: #FFBA49;
    font-weight:normal;
    position: absolute;
    right:90px;
    top:80px;
    z-index: 30;
}
.copyright p{
    margin: 0px;
    font-size: 12px;
    position: relative;
}
.copyright{
    padding-bottom: 20px;
    text-align: center;
}
@media screen and (max-width: 1146px){
    .footer-body{
        padding: 50px 30px 0px;
    }
    /* .footer-body div{
        padding:0px 40px;
    } */
    .footer-bottom div{
        padding:10px;
    }
}
@media screen and (max-width: 1000px){
    .footer-contact {
        width: 100%;
    }
    .footer-contact div{
        padding:20px;
    }
}
@media screen and (max-width: 890px){
    .footer-head-text {
        font-size: 15px;
    }
    .footer-body{
        font-size:13px;
        padding:10px 10px;
    }
    /* .footer-body div{
        padding: 0px 20px;
    } */
    .footer-contact a{
        font-size: 12px;
    }
     
    /* .footer-contact{
        display: block;
        padding:0px;
    } */
    /* .footer-contact div{
        padding:5px 0px;
    } */
    .footer-bottom{
        display: block;
    }
    .footer-bottom div{
        width:100%;
        padding:10px 8px;
        text-align: center;
    }
    .footer-text{
        display: none;
    }
    .footer-bottom p{
        font-size: 10px;
    }
    .footer-contact i{
        position: static;
    }
    .footer-head-inner-container i{
        display: none;
    }
    .footer-head-container{
        position:static; 
     }
}
@media screen and (max-width: 750px){
    .order1{
        order: 1;
    }
    .order2{
        order: 3;
    }
    .order3{
        order: 1;
    }
}
@media screen and (max-width: 500px){
    .footer-contact div{
        align-items: start;
    }
}