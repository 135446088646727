
body{
    margin: 0px;
    padding: 0px;
    width:100%;
    font-style: normal;
}
h1{
    font-size:unset;
}
img, svg, video, canvas, audio, iframe, embed, object {
    display: inline;
    max-width: unset;
}
*{
    box-sizing: border-box;
 }
.body-wrapper{
    width: 65%;
    margin: auto;
}
.header-container{
    width: 100%;
}
/* .text-center{
    text-align: unset;
} */
@media screen and (max-width: 2584px){
    .body-wrapper{
        width: 75%;
    }
}
@media screen and (max-width: 1920px){
    .body-wrapper{
        width: 100%;
        margin: unset;
    }
    .header-container{
        width: 100%;
    }
}