.team-container{
    padding-top: 50px;
    background-image: url("../../assets/images/NIMBUS_BACKGROUND_LOGO.png");
    background-size:700px 500px;
    background-repeat:no-repeat; 
    background-position: 50% -20px;
    position: relative;
}
.team-header{
    width: 30%;
    margin:auto;
    text-align: center;
}
.team-header h1{
    font-size: 40px;
    color:#272D4E;
    font-weight: 900;
}
.team-header p{
    font-size:15px ;
    color:#5C6774;
    padding :0px 40px;
    font-weight: 400;
}
.team-profile-frame{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    margin: auto;
    padding:40px 0px;
    margin-bottom: 40px;
}
.team-img-frame{
    width: 33.3%;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 2300px) {
    .team-profile-frame{
        width: 70%;
    }
}
@media screen  and (max-width: 1418px){
    .team-header{
        width:40%;
    }
    .team-profile-frame{
        width: 80%;
    }
}
@media screen  and (max-width: 1200px){
   
    .team-profile-frame{
        width: 90%;
    }
}
@media screen  and (max-width: 1063px){
    .team-header{
        width:50%;
    }
    .team-profile-frame {
        width: 95%;
    }

}
@media screen  and (max-width: 970px){
    .team-container{
        background-size: 600px 500px
    }
    .team-header{
        width:70%;
    }
    .team-img-frame{
        width: 50%;
    }
}
@media screen  and (max-width: 700px){
    .team-img-frame{
        width: 100%;
    }
    .team-container{
        background-size: 600px 500px
    }
}
@media screen  and (max-width: 600px){
    .team-img-frame{
        width: 100%;
    }
    .team-container{
        background-size: 100% 400px;
        background-position: 50% 10px;
    }
    .team-header{
        width: 80%;
    }
    .team-header h1 {
        font-size: 35px;
    }
}
@media screen  and (max-width: 420px){
    .team-header h1 {
        font-size: 30px;
    }
    .team-header{
        width: 90%;
    }
    .team-container{
        background-size: 100% 300px;
    }  
    .team-header p {
        font-size: 12px;
        padding: 0px 20px;
    }

}