.about-section1-container{
    padding-bottom: 70px;
}
.inner-about-section1{
    margin: 0 auto;
    width:70%;
    display: flex;
}
.inner-about-section2,
.inner-about-section3,
.inner-about-section4,
.inner-about-section5{
    margin: 0 auto;
    width:80%;
    display: flex;
}
.about-section1{
    background-image: url("../../assets/images/NIMBUS_BACKGROUND_LOGO.png");
    background-size:700px 450px;
    background-repeat:no-repeat; 
    background-position: 50% 0%;
    position: relative;
    margin-top: 180px;
    padding-top:90px ;
    padding-bottom: 120px;
}
.about-header2-background{
    position: relative;
    display: inline-block;
    width:250px;
    height: 15px;
    background-color: #0F2D62;
}
.about-header3-background,
.about-header4-background,
.about-header5-background,
.about-header6-background{
    position: relative;
    display: inline-block;
    height: 15px;
    background-color: #0F2D62;
}
.about-header3-background{
    width:350px;
}
.about-header4-background,
.about-header5-background
{
    width:350px;
}
.about-header6-background{
    width:500px;
}
.about-header2-background h1,
.about-header4-background h1,
.about-header6-background h1{
    top:-20px;
    position: absolute;
    margin: 0px;
    font-size:25px;
    color: #FFE919;
}
.about-header3-background h1,
.about-header5-background h1{
    top:-20px;
    position: absolute;
    margin: 0px;
    right:5px;
    font-size:25px;
    color: #FFE919;
}
.about-image-wrapper2,
.about-image-wrapper3{
    width:60%;
    position: relative;
}
.about-image-wrapper4,
.about-image-wrapper5{
    position: relative;

}
.about-image-wrapper img{
    width:400px;
    height:400px;
}
.about-image-wrapper2 img,
.about-image-wrapper3 img,
.about-image-wrapper4 img,
.about-image-wrapper5 img{
    z-index: -1;
    width:800px;
    height:400px;
}
.about-content-wrapper{
    color:#94A2B3;
    line-height: 1.5;
    font-size: 20px;
}
.about-content-wrapper2,
.about-content-wrapper4{
    color:#94A2B3;
    padding-right:5%;
    line-height: 1.5;
    font-size: 15px;
    padding-top: 40px;
}
.about-content-wrapper3,
.about-content-wrapper5{
    padding-top: 40px;
    color:#94A2B3;
    padding-left:5%;
    line-height: 1.5;
    font-size: 15px;
    text-align:right;
}

.about-content-wrapper button{
    background-color:#16A4DE;
    color:#FFFFFF;
    padding:10px 40px;
    border-radius: 30px;
    border-style: none;
}
.about-content-wrapper2 button,
.about-content-wrapper3 button,
.about-content-wrapper4 button,
.about-content-wrapper5 button{
    background-color:#FFE919;
    color:#0F2D62;
    padding:15px 60px;
    border-radius: 30px;
    border-style: none;
}
#digital-screen-small-circle{
    width:50px;
    height:50px;
    position: absolute;
    top:35px;
    left:95px;
}

#digital-screen-big-circle{
    width:270px;
    height:270px;
    position: absolute;
    top:100px;
    left:100px;
}
#digital2-screen-big-circle{
    width:200px;
    height:200px;
    position: absolute;
    top:-110px;
    right:90px;
}
#digital-screen-vertical-dots{
    width:180px;
    height:330px;
    position: absolute;
    top:230px;
    left:100px;
}
#digital2-screen-horizontal-dots{
    width: 400px;
    height: 160px;
    position: absolute;
    top:50px;
    right:30px;
}
.about-btn-wrapper{
    position: relative;
}
#btn-small-circle{
    width:50px;
    height:50px;
    position: absolute;
    top:27px;
    left:110px;
}
