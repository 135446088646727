.footer-contact{
    background:#1E52AE;
    color: #FFFFFF;
    flex-wrap: wrap;
    padding-top: 20px;
}
.footer-contact a{
    color: #FFFFFF;
    text-decoration: none;
}
@media screen and (max-width: 1300px){
    .footer-img{
        display: none;
    }
   
}
@media screen and (max-width: 750px){
   .footer-contact div{
    padding: 10px;
   }
    .order1{
        order: 1;
    }
    .order2{
        order: 3;
    }
    .order3{
        order: 1;
    }
}
@media screen and (max-width: 400px){
    .footer-contact div{
     padding: 10px 0px;
    }
}