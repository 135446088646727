.header-container{
    position: fixed;
    z-index:10;
    top:0px;
    opacity: 1;
    right: 0px;
    left:0px;
    width: 100%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    margin:auto;
}
.logo-wrapper{
    text-align: right;
}
.logo-wrapper-img{
    object-fit: cover;
    cursor: pointer;
}
.nav-wrapper{
    text-align: left;
}
.logo-wrapper img{     
    width: 100px;
    height: 80px;
}
.logo-wrapper i{
    display: none;
}  
.side-nav-logo-wrapper{
    display: none;
}

.sign-in{
    padding:2px 10px;
    border:2px solid #272D4E;
    border-radius: 60px;
    margin-left: 40px;
}
.signin-wrapper a{
    color: #272D4E;
    text-decoration: none !important;
    font-weight: 600;
    font-style: normal;
}
.side-nav-signin-wrapper{
    display: none;
}
.header-nav-ul{
    align-items: center;
    justify-content: left;
    display: flex;
    justify-content: center;
    margin: 0px;
}
.header-nav-ul li{
    list-style-type: none;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    padding:10px 35px;
    cursor:pointer;
}
.header-nav-ul li a{
    text-decoration: none;
    color:#272D4E;
}
.header-nav-ul li a:hover{
    color:#16A4DE;
}
.active{
    border-bottom: 3px solid #16A4DE; 
    border-radius: 3px;
    cursor: pointer;
}
.about-heaeder-nav{
    position: relative;
}
.about-dropdown-container{
    background-color: #16A4DE;
    width: 250px;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    margin-top: 10px;
    display: none;
}
.about-dropdown-container hr{
    margin: 0px;
    background-color: #E8F1FF;
    border:1px solid #E8F1FF;
}

.dropdown-content p {
    margin:0px;
}
.dropdown-content p a{
    color: #FFFFFF;
    padding: 10px 20px;
    display: block;
}
.dropdown-content p a:hover{
    opacity: .7;
    cursor: pointer;
    color:#FFFFFF;
}

.header-content button {
    font-size: 18px;
}
.icon{
    display: none;
    cursor: pointer;
}
.inactive{
    border-bottom: unset;
}
.side-nav-backdrop{
    display: none ;
}

@media screen and (max-width: 1440px){
    .header-nav-ul li{
        font-size: 16px;
        padding:10px 30px;
    }
}

@media screen and (max-width: 1132px){
    .sign-in{
        margin-left: 0px;
    }
}
@media screen and (min-width:760px){
    .about-dropdown-container{
        border-style: none;
    }
    .about-header-nav:hover .about-dropdown-container{
        display: block;
    }
}
@media screen and (max-width:760px){
    .header-container{
        display: block;
    }
    .side-nav-backdrop{
        width: 100%;
        height: 100vh;
        position: fixed;
        top:0px;
        left:0px;
        display: block;
        background: #FFFFFF;
        opacity: .4;
    }
    .logo-wrapper{
        width: 100%;
        text-align: unset;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo-wrapper i{
        right:30px;
        font-size: 35px;
        color:rgba(22, 164, 222, 1);
        display: inline;
    } 
    .nav-wrapper.activeNav{
        width:60%;
    }
    .nav-wrapper{
        height: 100vh;
        background-color:#E8F1FF;
        position: absolute;
        top:0px;
        left:0px;
        overflow-x: hidden;
        color: #FFFFFF;
        width:0%;
        transition: all 1s;
    }
    .header-nav-ul{
        display: block;
        padding-left: 0px;
        min-width:300px;
    }
    .header-nav-ul li{
        padding: 0px;
        border-bottom:  1px solid #16A4DE;
    }
    .header-nav-ul li a{
        display: block;
        padding: 20px 30px;
        color: #272D4E;
        border-radius: 0px;
    }
    .header-nav-ul li a:hover{
        color: rgba(22, 164, 222, 1);
    }
    .header-container{
        padding: 10px;
    }
    .about-dropdown-container{
        background-color: #E8F1FF;
        color: rgba(22, 164, 222, 1);
        position: unset;
        width: 100%;
        text-align: left;
    }
    /* .about-header-nav:hover .about-dropdown-container{
        display: unset;
    } */
    .dropdown-content{
        padding-left: 20px;
    }
    .side-nav-logo-wrapper{
        display: block;
    }
    .side-nav-logo-wrapper i{
        position: absolute;
        right:30px;
        top:50px;
        font-size: 35px;
    } 
    .dropDownIsActive{
        display: block;
    }
    .dropDownIsNotActive{
        display: none;
    }
    .side-nav-logo-wrapper img{   
        margin-left: 20px; 
        float: left;  
        width: 100px;
        height: 80px;
    }
    
    .icon.activeNav{
        display: none;
    }
    .side-nav-logo-wrapper{
        display: block;
    }
   
    .header-nav-ul{
        display: block;
        padding-left: 0px;
        min-width:300px;
    }
    .header-container{
        padding: 10px;
    }
    .active{
        color: #16A4DE !important;
        border-bottom:unset;
    } 
    .side-nav-signin-wrapper span{
        padding: 5px 20px;
        background-color: #16A4DE;
        color: #FFFFFF;
        border-radius: 40px;
    }
    .sign-in{
        border: 0px;
    }
    .side-nav-signin-wrapper{
        display: list-item;
    }
    .signin-wrapper{
        display: none;
    }
}
/* @media screen and (max-width: 890px){
    .side-nav-logo-wrapper img,
    .logo-wrapper img{   
        width: 60px;
        height: 50px;
    }
    .header-content button {
        font-size: 12px;
    }
} */