.image-modal{
    position: fixed;
    top:50%;
    left:50%;
    margin-left: -50%;
    margin-top: -325px;
    width: 100%;
    height: 650px;
    background-color: transparent;
    z-index: 70;
    animation: slide-down 300ms ease-out forwards;
}
.image-modal img{
  margin: auto;
  width: 700px;
  height: 650px;
}
@keyframes slide-down {
    from {
      opacity: 1;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
@media screen and (max-width:630px) {
  .image-modal{
    margin-top: -200px;
  }
  .image-modal img{
    margin: auto;
    width: 100%;
    height: 400px;
  }
  
}