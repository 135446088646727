.team-img-outer-container{
    margin: 20px;
    width: 300px;
    min-width:300px
}
.team-img-container{
    width: 100%;
    height: 400px;
    position: relative;
}
.team-img-container img{
    width: 100%;
    height: 100%;
}
.team-title-container{
    background:rgba(63, 69, 77, 0.52) ;
}
.team-inner-title-container{
    position: relative;
    top:0%;
}
.team-title-container h1{
    font-size: 20px;
    margin: 0px;
}
.team-title-container p{
    font-size: 12px;
    margin: 0px;
}
.edit-team-container{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 5px 5px 8px #888888;
}
.edit-team-container button{
    background-color:transparent;
    font-weight: 600;
    font-size: 17px;
    border:2px solid #272D4E;
    border-radius: 60px;
    color: #272D4E;
    width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0px 5px;
}
.edit-team-container button:hover{
    background:#272D4E;
    border-style: none;
    color:#FFFFFF;
}
.edit-team-container span{
    position: relative;
    top:-2px;
    margin-right: 3px;
}

@media screen  and (max-width: 400px){
    .team-img-outer-container {
        width: 270px;
        min-width: 270px;
    }
}