.about-template-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.one{
    order: 1
}
.two{
    order:2
}
.opportunity-icon-wrapper:hover{
    transform: scale(1.1);
    transition: transform 300ms;
}
.about-text-wrapper{
    color: #272D4E;
}
@media screen and (min-width: 200px) {
    .one{
        order: 2
    }
    .two{
        order:1
    }
    .about-template-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
   
}
@media screen and (min-width: 1024px) {
    .one{
        order: 1
    }
    .two{
        order:2
    }
    .about-template-container{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}