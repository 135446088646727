.skeleton{
    background:#ddd;
    border-radius: 4px;
}
.skeleton.text{
    width: 100%;
    height:25px;
}
.skeleton.title{
    width: 50%;
    height:20px;
}
.skeleton.button{
    border-radius: 60px;
    width: 85px;
    height:40px;
}
.skeleton.avatar{
    width: 100px;
    height:100px;
    border-radius: 50%;
}
.skeleton.thumbnail{
    width: 100%;
    height:100%;
}
/* profile skeleton */
.skeleton-profile-wrapper{
    width: 300px;
    min-width: 300px;
    height: 400px;
    margin: 20px;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
}
.skeleton-profile-wrapper.dark{
    background: #444;
}
.skeleton-profile-wrapper.dark .skeleton{
    background: #777;
}
.skeleton-profile-wrapper.light{
    background: #f2f2f2;
}

/* gallery skeleton */
.skeleton-gallery-wrapper{
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    background:#f2f2f2;
    margin:auto;
    border-radius: 4px;
    
}

/* blog skeleton */
.skeleton-blog-wrapper{
    max-width: 350px;
    min-width: 350px;
    height: 500px;
    overflow: hidden;
    position: relative;
    background:#f2f2f2;
    margin:40px 20px;
    border-radius: 4px;
}


/* shimmer */
.shimmer-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 1.5s infinite;
}
.shimmer{
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, .4);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}
@keyframes loading{
    0%{ transform: translateX(-150%);}
    50%{ transform: translateX(-60%);}
    100%{ transform: translateX(150%);}
}