.blog-container{
    padding-top: 50px;
    width: 100%;
    /* background: linear-gradient(180deg, rgba(229, 239, 255,1) 40%, rgba(229, 239, 255,.26) 55%,rgba(229, 239, 255, 0) 100%); */
    background-image: url("../../assets/images/NIMBUS_BACKGROUND_LOGO.png");
    background-size:700px 500px;
    background-repeat:no-repeat; 
    background-position: 50% 0%;
    position: relative;
}
.blog-header-text h1{
    font-size: 50px;
    margin: 0px;
    color: #272D4E;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
}
.blog-inner-container{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;
}
.footer-card-wrapper{
    width: 50%;
}
.footer-card-wrapper:nth-child(odd) {
    display: flex;
    justify-content: right;
}

.blog-footer{
    background-color:#092049;
    color: #FFFFFF;
}
.blog-inner-footer{
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: left;
    padding: 30px 0px;
}
.blog-outer-slider-img{
    width: 33.3%;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:1930px) {
    .blog-inner-container{
        width:80%;
    }
}
@media screen and (max-width:1750px) {
    .blog-inner-container{
        width:80%;
    }
}
@media screen and (max-width:1460px) {
    .blog-outer-slider-img{
        width: 33.3%;
    }
}
@media screen and (max-width:1320px) {
    .footer-card{
        width: 100%;
    }
    .blog-inner-container{
        width:90%;
    }
}
@media screen and (max-width:1260px) {
    .blog-inner-container{
        width:95%;
    }
}

@media screen and (max-width:1000px) {
    .footer-card h1{
        margin-right: 20px;
    }
    .blog-outer-slider-img{
        width: 50%;
    }
    .blog-inner-container{
        width:85%;
    }

}
@media screen and (max-width:730px) {
    .blog-inner-container{
        width:90%;
    }
    .blog-header-text h1{
        font-size: 38px;
    }
}
@media screen and (max-width:680px) {
    .blog-outer-slider-img{
        width: 100%;
    }
    .blog-container{
        background-size:500px 400px;
    }
}
@media screen and (max-width:450px) {
    .blog-outer-slider-img{
        width: 100%;
    }
    .blog-inner-container{
        width:100%;
    }
    .blog-container{
        background-size:400px 350px;
    }
}